import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  onEnter: (value: number | '') => void;
  sector: number;
};

const Input = ({ onEnter, sector }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number | ''>('');

  useEffect(() => {
    setValue('');
  }, [sector]);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <input
          type={'number'}
          placeholder={t('enterSector') || ''}
          className={styles.innerInput}
          onChange={(e) => setValue(Number(e.target.value) || '')}
          value={value}
        />
      </div>
      <span className={styles.ok} onClick={() => onEnter(value)}>
        OK
      </span>
    </div>
  );
};

export default Input;
