import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';
import { Button } from '../../components';
import '../../App.scss';
import styles from './styles.module.scss';
import Surface from '../../components/Surface';
import prizeDrawSurfaceBackground from '../../assets/prizeDrawSurfaceBackground.svg';

const PrizeDraw = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(APP_ROUTES.CATALOGUE);
  };
  return (
    <div className={styles.container}>
      <Surface color="orange" background={`url(${prizeDrawSurfaceBackground})`}>
        <div className={styles.contentWrapper}>
          <h1 className={styles.headline}>
            <Trans t={t} i18nKey="prizeDrawText"></Trans>
          </h1>
          <div className={styles.buttons}>
            <Button
              borderColor="orange"
              variant="tertiary"
              text={t('back')}
              onClick={navigateBack}
            />
            <Link to="https://t.me/lakartiphoto" target="_blank">
              <Button
                borderColor="orange"
                variant="secondary"
                text={t('prizeDrawButton')}
              />
            </Link>
          </div>
        </div>
      </Surface>
    </div>
  );
};

export default PrizeDraw;
