import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import styles from './styles.module.scss';
import { MatrixType } from '../../constants/diamonds';
import { useTranslation } from 'react-i18next';
import { PhotoContext } from '../../context/PhotoProvider';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import api from '../../services/api';
import { API_ROUTES } from '../../constants/api_routes';
import { CompletionType, ProductTypes } from '../../types/enums';
import EmbroiderySvg from '../../assets/embroidery/EmbroiderySvg';
import DotPaintSvg from '../../assets/dotPaints/DotPaintSvg';
import DiamondSvg from '../../assets/diamonds/DiamondSvg';
import { clsx } from 'clsx';
import emptySvg from '../../assets/diamonds/empty.svg';

const isLight = (color: string) => {
  if (color.length == 7) {
    const rgb = [
      parseInt(color.substring(1, 3), 16),
      parseInt(color.substring(3, 5), 16),
      parseInt(color.substring(5), 16),
    ];
    const luminance =
      (0.2126 * rgb[0]) / 255 +
      (0.7152 * rgb[1]) / 255 +
      (0.0722 * rgb[2]) / 255;
    return luminance > 0.5;
  }
  return false;
};

type MosaicGridType = {
  section: number;
  colorInfo: { [key: string]: { hex: string } };
  setSectorsInfo: Dispatch<SetStateAction<{ [key: number]: CompletionType }>>;
};

const iconsMap = {
  [ProductTypes.EMBROIDERY]: EmbroiderySvg,
  [ProductTypes.DOT_PAINTING]: DotPaintSvg,
  [ProductTypes.MOSAIC]: DiamondSvg,
  [ProductTypes.PAINTING]: DotPaintSvg,
};

const MosaicGrid = ({ section, colorInfo, setSectorsInfo }: MosaicGridType) => {
  const { t } = useTranslation();
  const [colorChosen, setColorChosen] = useState<string[]>([]);

  const { matrix, setMatrix, order, productType } = useContext(PhotoContext);

  const [mementos, setMementos] = useState([matrix]);

  const changeSquare = async (block: number, is_filled: boolean) => {
    const res = await api({
      URL: API_ROUTES.INSTRUCTION_CHANGE_SQUARE,
      options: {
        method: 'POST',
        body: { order, block, is_filled: is_filled ? 1 : 0 },
      },
    });
    setMatrix(res.matrix);
    setSectorsInfo(res.all_completion);
  };

  const changeSection = async (matrix: MatrixType[][]) => {
    const res = await api({
      URL: API_ROUTES.INSTRUCTION_CHANGE_SECTION,
      options: {
        method: 'POST',
        body: { order, section, matrix },
      },
    });
    setMatrix(res.matrix);
    setSectorsInfo(res.all_completion);
  };

  const onSquare = (el: MatrixType) => {
    if (chooseColor(el.type) && el.type !== 'empty') {
      changeSquare(el.id, !el.interactive);
      setMementos([...mementos, matrix]);
    } else {
      return;
    }
  };

  const onDoubleClick = (rowIndex: number, el: MatrixType, index: number) => {
    if (chooseColor(el.type) && el.type !== 'empty') {
      const matrixCopy = matrix.map((row, i) => {
        if (i === rowIndex) {
          return row.map((e, i) => {
            if (i < index) {
              return { ...e, interactive: true };
            }
            return e;
          });
        } else {
          return row;
        }
      });
      changeSection(matrixCopy);
    } else {
      return;
    }
  };

  const colors = [...new Set(matrix.flat().map((element) => element.type))];

  const onChooseColor = (el: string) => {
    const arr = colorChosen ? [...colorChosen] : [];
    let res: any[];
    if (arr.includes(el)) {
      const index = arr.indexOf(el);
      arr.splice(index, 1);
      res = [...arr];
    } else {
      arr.push(el);
      res = [...arr];
    }
    setColorChosen(res);
  };
  const turnOnAllColors = () => setColorChosen([]);

  const chooseColor = (type: string) => {
    if (colorChosen.length) {
      return colorChosen.includes(type);
    } else {
      return true;
    }
  };

  const getOrder = (row: MatrixType[], el: MatrixType, index: number) => {
    const array = row.filter((item) => item.type === el.type);

    if (array.length === row.length) {
      return index + 1;
    } else {
      const splittedArray = row.slice(0, index + 1);
      const lastMismatchedElement = splittedArray.findLastIndex(
        (item: MatrixType) => item.type !== el.type,
      );
      const arr = splittedArray.slice(lastMismatchedElement + 1, index + 1);
      return arr.length;
    }
  };

  const onReset = () => {
    const matrixCopy: MatrixType[][] = matrix.map((row) => {
      return row.map((e) => {
        return { ...e, interactive: false };
      });
    });
    changeSection(matrixCopy);
  };

  const onUndo = () => {
    const lastMemento = mementos.pop();
    if (!lastMemento) {
      return;
    } else {
      changeSection(lastMemento);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.colorButtonsWrapper}>
        <div className={styles.colorButtons}>
          {colors.map((el) => {
            if (el !== 'empty') {
              return (
                <div key={el} onClick={() => onChooseColor(el)}>
                  <Square
                    colorInfo={colorInfo}
                    type={el}
                    filled={false}
                    chosen={chooseColor(el)}
                    productType={productType}
                  />
                </div>
              );
            }
          })}
        </div>
        <button className={styles.allColors} onClick={turnOnAllColors}>
          {t('turnOnAllColors')}
        </button>
      </div>
      <table className={styles.grid}>
        <tbody>
          <tr>
            <td></td>
            {[...Array(matrix[0].length)].map((_, i) => (
              <td className={styles.headerRow} key={i}>
                {i + 1}
              </td>
            ))}
            <td></td>
          </tr>
          {matrix.map((arr, index) => (
            <tr className={styles.mosaicRow} key={index}>
              <td className={styles.headerColumn}>{index + 1}</td>
              {arr.map((el, i) => (
                <td
                  key={i}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    if (e.detail === 1) {
                      onSquare(el);
                    } else {
                      onDoubleClick(index, el, i);
                    }
                  }}
                >
                  <Square
                    filled={el.interactive}
                    type={el.type}
                    order={getOrder(arr, el, i)}
                    chosen={chooseColor(el.type)}
                    productType={productType}
                    colorInfo={colorInfo}
                  />
                </td>
              ))}
              <td className={styles.headerColumn}>{index + 1}</td>
            </tr>
          ))}

          <tr className={styles.headerRow}>
            <td></td>
            {[...Array(matrix[0].length)].map((_, i) => (
              <td className={styles.headerRow} key={i}>
                {i + 1}
              </td>
            ))}
            <td></td>
          </tr>
        </tbody>
      </table>
      <div className={styles.controls}>
        <button onClick={onReset} className={styles.reset}>
          {t('reset')}
        </button>
        <button onClick={onUndo} className={styles.cancel}>
          <Cancel />
        </button>
      </div>
    </div>
  );
};

export default MosaicGrid;

interface SquareProps {
  filled: boolean;
  type: string;
  order?: number;
  chosen: boolean;
  productType: ProductTypes;
  colorInfo: MosaicGridType['colorInfo'];
}

const Square = ({
  filled,
  type,
  order,
  chosen = true,
  productType,
  colorInfo,
}: SquareProps) => {
  const color = `#${colorInfo[type]?.hex || ''}`;
  const Svg = iconsMap[productType];
  const textStyle = isLight(color) ? { color: 'black' } : undefined;
  return (
    <div className={type === 'empty' ? styles.empty : styles.square}>
      {filled ? (
        <Svg color={color} />
      ) : (
        <div
          className={clsx(
            styles.type,
            !chosen && type !== 'empty' ? styles.chosen : undefined,
          )}
          style={type !== 'empty' ? { backgroundColor: `${color}` } : {}}
        >
          {type === 'empty' ? (
            <img src={emptySvg} alt={type + ' diamond'} />
          ) : (
            <>
              <span style={textStyle}>{order}</span>
              <p style={textStyle}>{type}</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
