import React from 'react';
import styles from './styles.module.scss';
import { Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';
import Image404 from '../../assets/img404.png';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const onClick = () => navigate(APP_ROUTES.MAIN_PAGE);

  return (
    <div className={styles.notFoundPage}>
      <div className={styles.wrapperContent}>
        <div className={styles.container}>
          <h1 className={styles.title}>404</h1>
          <span className={styles.subtitle}>Страница не найдена</span>
        </div>
        <div className={styles.wrapperImg}>
          <img src={Image404} alt="bg 404" />
        </div>
        <div className={styles.container}>
          <p className={styles.text}>
            Кажется, что-то пошло не так, но мы работаем над этим. Пожалуйста,
            перейдите на главную страницу.
          </p>
          <Button text={'На главную'} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
