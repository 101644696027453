import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { PhotoContext } from '../../context/PhotoProvider';
import { useNavigate } from 'react-router';
import { APP_ROUTES } from '../../constants/appRoutes';
import Block from '../../components/Block';
import { Colors } from '../../constants/colorMap';
import { Button } from '../../components';
import { PHOTO_KIT } from '../../constants/photoKit';

const ChooseStyle = () => {
  const { t } = useTranslation();
  const { setStyleVariant } = useContext(PhotoContext);
  const navigate = useNavigate();

  const handleClick = (variant: number) => {
    setStyleVariant(variant);
    navigate(APP_ROUTES.HOW_TO_CHOOSE_PHOTO, {
      state: {
        navigateToEmail: true,
      },
    });
  };
  const navigateBack = () => {
    navigate(APP_ROUTES.INSTRUCTION);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('chooseStyle')}</h1>
      {PHOTO_KIT.map((el, index) => {
        return (
          <Block
            key={index}
            color={el.color as Colors}
            text={<p className={styles.text}>{t('style')}</p>}
            description={<p className={styles.description}>{el.name}</p>}
            buttonText={t('choose')}
            buttonAction={() => handleClick(el.id)}
          />
        );
      })}
      <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
    </div>
  );
};

export default ChooseStyle;
