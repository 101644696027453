import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '..';
import { APP_ROUTES } from '../../constants/appRoutes';
import { useTranslation } from 'react-i18next';
import { PhotoContext } from '../../context/PhotoProvider';
import { uploadImg } from '../../services/uploadImg';
import { ReactComponent as TheScream } from '../../assets/theScream.svg';
import styles from './styles.module.scss';
import Surface from '../Surface';
import { ProductTypes } from '../../types/enums';

interface IBlockOnPageProps {
  title: string;
  text: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
  fileInputRef?: React.RefObject<HTMLInputElement>;
  withFileInp?: boolean;
  navigateNextLink?: string;
  isDemo?: boolean;
}

const routesMap = {
  [ProductTypes.MOSAIC]: {
    getBackRoute: () => APP_ROUTES.HOW_TO_CHOOSE_PHOTO,
  },
  [ProductTypes.PAINTING]: {
    getBackRoute: (isDemo: boolean) =>
      !isDemo ? APP_ROUTES.PAINTING : APP_ROUTES.CHOOSE_SIZE_PAINTING,
  },
  [ProductTypes.EMBROIDERY]: {
    getBackRoute: () => APP_ROUTES.HOW_TO_CHOOSE_EMBROIDERY_PHOTO,
  },
  [ProductTypes.DOT_PAINTING]: {
    getBackRoute: () => APP_ROUTES.HOW_TO_CHOOSE_DOT_PAINTING_PHOTO,
  },
};

const UploadContainer = ({
  title,
  text,
  description,
  buttonText,
  buttonAction,
  fileInputRef,
  withFileInp = false,
  navigateNextLink,
  isDemo = false,
}: IBlockOnPageProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { productType, setPhoto } = useContext(PhotoContext);

  const navigateNext = () => {
    navigateNextLink
      ? navigate(navigateNextLink, { state: { navigateToEmail: true } })
      : navigate(APP_ROUTES.EDITOR);
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = fileInputRef?.current?.files?.[0]?.size;
    if (file) {
      const fileSize = file / 1048576;
      if (fileSize > 15) {
        alert(t('fileSizeIsBigger'));
        return;
      }
    }
    uploadImg(e, setPhoto);
    navigateNext();
  };

  return (
    <div className={styles.pageContent}>
      <Button
        text={t('back')}
        variant="tertiary"
        onClick={() =>
          navigate(
            routesMap[productType].getBackRoute(isDemo),
            !isDemo
              ? {
                  state: { navigateToEmail: true },
                }
              : {},
          )
        }
      />
      <div className={styles.wrapperMainContent}>
        <h1>{title}</h1>
        <Surface border>
          <p className={styles.text}>{text}</p>
          <p className={styles.description}>{description}</p>
          <TheScream />
          <Button variant="download" text={buttonText} onClick={buttonAction} />
          <span className={styles.message}>{t('fileSizeLessThen15mb')}</span>
          {withFileInp && (
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageSelect}
            />
          )}
        </Surface>
      </div>
      <div className={styles.wrapperMobileContent}>
        <Surface>
          <h1>{title}</h1>
          <span className={styles.message}>{t('fileSizeLessThen15mb')}</span>
          <Button variant="download" text={buttonText} onClick={buttonAction} />
        </Surface>
      </div>
    </div>
  );
};

export default UploadContainer;
