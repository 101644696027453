import dark from '../assets/dark.jpg';
import original from '../assets/original.jpg';
import light from '../assets/light.jpg';

export const STYLES_ARR = [
  {
    id: 0,
    src: dark,
  },
  {
    id: 1,
    src: original,
  },
  {
    id: 2,
    src: light,
  },
];
