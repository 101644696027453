import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { APP_ROUTES } from '../../constants/appRoutes';
import MarketplaceBlock from '../../components/MarketplaceBlock';
import mainGirl from '../../assets/mainGirl.png';
import Block from '../../components/Block';
import styles from './styles.module.scss';

const MainPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToCatalogue = () => {
    navigate(APP_ROUTES.CATALOGUE);
  };

  return (
    <div>
      <Block
        text={t('mainHeadline')}
        description={t('mainDescription') ?? ''}
        buttonText={t('choose')}
        variant="primary"
        alignment="vertical"
        main
        buttonAction={navigateToCatalogue}
        image={mainGirl}
      />
      <div className={styles.marketplaces}>
        <p className={styles.marketplacesText}>{t('marketplaces')}</p>
        <div className={styles.marketplacesBlocks}>
          <MarketplaceBlock type="wb" size="medium"></MarketplaceBlock>
          <MarketplaceBlock type="ozon" size="medium"></MarketplaceBlock>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
