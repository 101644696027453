import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonRu from './translations/ru/index.json';
import commonEn from './translations/en/index.json';
import commonBlr from './translations/blr/index.json';
import commonGeu from './translations/geu/index.json';
import commonKaz from './translations/kaz/index.json';
import commonUkr from './translations/ukr/index.json';

i18n.use(initReactI18next).init({
  resources: {
    ru: {
      common: commonRu,
    },
    en: {
      common: commonEn,
    },

    by: {
      common: commonBlr,
    },

    de: {
      common: commonGeu,
    },

    kz: {
      common: commonKaz,
    },

    ua: {
      common: commonUkr,
    },
  },
  lng: localStorage.getItem('lang') || 'ru',
  fallbackLng: localStorage.getItem('lang') || 'ru',
  ns: ['common'],
  defaultNS: 'common',
});

export default i18n;
