import React, { useContext, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PhotoContext } from '../../context/PhotoProvider';

import '../../App.scss';

import styles from './styles.module.scss';
import Surface from '../../components/Surface';
import MarketplaceBlock from '../../components/MarketplaceBlock';
import { APP_ROUTES } from '../../constants/appRoutes';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import { ProductTypes } from '../../types/enums';

const stylesMap = {
  black: 'Original',
  'pop-art': 'Pop Art',
  sepia: 'Sepia',
};

const dataByProductTypeMap = {
  [ProductTypes.MOSAIC]: {
    title: 'thisIsHowYourFutureDiamondWillLook',
    uploadPhotoLink: APP_ROUTES.UPLOAD_PHOTO,
    stylesMap,
  },
  [ProductTypes.PAINTING]: {
    title: 'thisIsHowYourFuturePaintingWillLook',
    uploadPhotoLink: APP_ROUTES.UPLOAD_PAINTING_PHOTO,
    stylesMap,
  },
  [ProductTypes.DOT_PAINTING]: {
    title: 'thisIsHowYourFuturePaintingWillLook',
    uploadPhotoLink: APP_ROUTES.UPLOAD_DOT_PAINTING_PHOTO,
    stylesMap,
  },
  [ProductTypes.EMBROIDERY]: {
    title: 'thisIsHowYourFuturePaintingWillLook',
    uploadPhotoLink: APP_ROUTES.UPLOAD_EMBROIDERY_PHOTO,
    stylesMap,
  },
};

const PhotoStyles = () => {
  const photoFilterFRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { productType } = useContext(PhotoContext);

  const navigateBack = () => {
    navigate(dataByProductTypeMap[productType].uploadPhotoLink);
  };

  const { editedPhotosArr } = useContext(PhotoContext);

  return (
    <div className={styles.photoStyleContainer} ref={photoFilterFRef}>
      <h1>{t(dataByProductTypeMap[productType].title)}</h1>
      <div className={styles.photosContainer}>
        {Object.keys(editedPhotosArr).map((key) => (
          <div key={editedPhotosArr[key]} className={styles.surfaceWrapper}>
            <Surface radius="54px">
              <img src={editedPhotosArr[key]} />
              <div className={styles.styleName}>
                {
                  dataByProductTypeMap[productType].stylesMap[
                    key as keyof typeof stylesMap
                  ]
                }
              </div>
            </Surface>
          </div>
        ))}
      </div>
      <div className={styles.purchaseContainer}>
        <h1>{t('orderTheSet')}</h1>
        <div className={styles.marketplacesBlocks}>
          <MarketplaceBlock type="wb" size="large" />
          <MarketplaceBlock type="ozon" size="large" />
        </div>
      </div>
      <Surface radius={window.innerWidth > 770 ? '45px' : '25px'}>
        <div className={styles.moreOptions}>
          <Trans t={t} i18nKey="moreOptions" />
        </div>
      </Surface>
      <div className={styles.buttonContainer}>
        <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
      </div>
    </div>
  );
};

export default PhotoStyles;
