import React, { useContext, useEffect, useRef, useState } from 'react';
import MosaicGrid from '../../components/MosaicGrid';
import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';
import { Button } from '../../components';
import SwiperCore from 'swiper';
import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as Arrow } from '../../assets/icons/rightBlack.svg';
import 'swiper/css';
import 'swiper/scss/scrollbar';
import { PhotoContext } from '../../context/PhotoProvider';
import api from '../../services/api';
import { API_ROUTES } from '../../constants/api_routes';
import { useParams } from 'react-router-dom';
import { CompletionType, ProductTypes } from '../../types/enums';
import InteractiveSchema from './InteractiveSchema';
import Input from '../../components/Input';
import { DEFAULT_COLOR_INFO } from '../../constants/defaultColorInfo';
import noImageAvailable from '../../assets/noImageAvailable.png';

const checkImage = async (url: string) => {
  const res = await fetch(url);
  const buff = await res.blob();
  return buff.type.startsWith('image/');
};

type InfoType = {
  sections: number;
  image_url: string;
  pdf_url: string;
  last_section: number;
  color_info: { [key: string]: { hex: string } };
};

const dataByTypeMap = {
  [ProductTypes.MOSAIC]: {
    navigateHowToUseRoute: APP_ROUTES.HOW_TO_USE_INSTRUCTION,
    text: 'youCanMark',
  },
  [ProductTypes.EMBROIDERY]: {
    navigateHowToUseRoute: APP_ROUTES.HOW_TO_USE_EMBROIDERY_INSTRUCTION,
    text: 'youCanMarkEmbroidery',
  },
  [ProductTypes.DOT_PAINTING]: {
    navigateHowToUseRoute: APP_ROUTES.HOW_TO_USE_DOT_PAINTING_INSTRUCTION,
    text: 'youCanMarkDotPainting',
  },
  [ProductTypes.PAINTING]: {
    navigateHowToUseRoute: APP_ROUTES.HOW_TO_USE_INSTRUCTION,
    text: 'youCanMark',
  },
};

const InteractiveInstruction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [sectorsInfo, setSectorsInfo] = useState<{
    [key: number]: CompletionType;
  }>({});
  const [info, setInfo] = useState<InfoType>({
    sections: 0,
    image_url: '',
    pdf_url: '',
    last_section: 0,
    color_info: {},
  });
  const [sector, setSector] = useState<number>(0);
  const { setMatrix, matrix, setOrder, productType } = useContext(PhotoContext);
  const { id } = useParams();

  const _id = id?.slice(3);
  if (_id) localStorage.setItem('orderId', _id);
  if (!_id && localStorage.getItem('orderId')) {
    history.pushState(null, '', ':id' + localStorage.getItem('orderId'));
  }

  SwiperCore.use([Scrollbar]);

  swiperRef?.on('progress', function (swiper: SwiperCore) {
    if (swiper.progress === 0) {
      setPrevDisabled(true);
    }
    if (swiper.progress === 1) {
      setNextDisabled(true);
    }
    if (swiper.progress > 0) {
      setPrevDisabled(false);
    }
    if (swiper.progress < 1) {
      setNextDisabled(false);
    }
  });

  const prevSlide = () => {
    swiperRef?.slidePrev();
  };
  const nextSlide = () => {
    swiperRef?.slideNext();
  };
  const blocksArr = [...Array(info.sections)];

  const fetchMatrix = async (section: number) => {
    if (section !== undefined) {
      setSector(section);
    }
    try {
      const res = await api({
        URL:
          API_ROUTES.INSTRUCTION_GET_MATRIX +
          (_id || localStorage.getItem('orderId')) +
          `&section=${section ?? sector}`,
      });
      if (res.matrix) {
        setMatrix(res.matrix);
      }
      if (res.all_completion) {
        setSectorsInfo(res.all_completion);
      }
    } catch (e) {
      console.error('Error while fetching block:', e);
    }
  };

  const fetchInfo = async () => {
    try {
      const res = await api({
        URL:
          API_ROUTES.INSTRUCTION_INFO +
          (_id || localStorage.getItem('orderId')),
      });
      if (res) {
        setOrder(res.order);
        const isImageAvailable = await checkImage(res.image_url);
        setInfo({
          sections: res.count_sections,
          image_url: isImageAvailable ? res.image_url : noImageAvailable,
          pdf_url: res.pdf_url,
          color_info: res.color_info,
          last_section: res.last_activity?.section ?? 0,
        });
      }
      fetchMatrix(res.last_activity?.section);
    } catch (e) {
      console.error('Error while loading mosaic info:', e);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const navigateHowToUse = () => {
    navigate(dataByTypeMap[productType].navigateHowToUseRoute);
  };

  const enterSectorHandle = (value: number | '') => {
    if (
      value === '' ||
      value < 0 ||
      value > info.sections ||
      !Number.isInteger(value)
    ) {
      return;
    }
    fetchMatrix(value - 1);
  };

  const isMobile = window.innerWidth <= 780;

  return (
    <div className={styles.container}>
      {productType === ProductTypes.EMBROIDERY && !isMobile && (
        <InteractiveSchema
          sector={sector}
          info={info}
          sectorsInfo={sectorsInfo}
          onEnterSector={enterSectorHandle}
        />
      )}
      <div className={styles.wrapper}>
        {productType === ProductTypes.EMBROIDERY && isMobile && (
          <>
            <InteractiveSchema
              sector={sector}
              info={info}
              sectorsInfo={sectorsInfo}
              onEnterSector={enterSectorHandle}
            />
            <div className={styles.customInput}>
              <span>
                {t('sector')} {sector + 1}
              </span>
              <Input onEnter={enterSectorHandle} sector={sector} />
            </div>
          </>
        )}
        <button onClick={navigateHowToUse} className={styles.howToUse}>
          {t('howToUseInstruction')}
        </button>
        <div className={styles.mosaicRow}>
          {productType !== ProductTypes.EMBROIDERY && (
            <img
              className={popupOpen ? styles.hidden : undefined}
              src={info.image_url}
              alt="small photo"
              onClick={() => setPopupOpen(true)}
            />
          )}
          {matrix.length <= 1 ? (
            <div className={styles.skeleton} />
          ) : (
            <MosaicGrid
              section={sector}
              colorInfo={info.color_info || DEFAULT_COLOR_INFO}
              setSectorsInfo={setSectorsInfo}
            />
          )}
        </div>
        {productType === ProductTypes.EMBROIDERY && !isMobile && (
          <div className={styles.customInput}>
            <span>
              {t('sector')} {sector + 1}
            </span>
            <Input onEnter={enterSectorHandle} sector={sector} />
          </div>
        )}

        {productType !== ProductTypes.EMBROIDERY && (
          <div className={styles.swiperWrapper}>
            <span className={styles.swiperText}>{t('sectors')}</span>
            <div className={styles.swiperContainer}>
              <div
                ref={navigationPrevRef}
                className={`${styles.prevButton} ${
                  prevDisabled ? styles.disabled : ''
                }`}
                onClick={prevSlide}
              >
                <Arrow />
              </div>
              <Swiper
                navigation
                freeMode
                scrollbar={{
                  el: '.swiper-scrollbar',
                  draggable: true,
                }}
                initialSlide={sector}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper: SwiperCore) => setSwiperRef(swiper)}
                slidesPerGroup={6}
                slidesPerView={6}
                spaceBetween={6}
                breakpoints={{
                  780: {
                    spaceBetween: 14,
                  },
                }}
              >
                {blocksArr.map((_el, i) => (
                  <SwiperSlide
                    key={i}
                    className={`${styles.slide} ${
                      sector === i ? styles.clickedSector : undefined
                    }`}
                    onClick={() => {
                      fetchMatrix(i);
                    }}
                  >
                    <span>{i + 1}</span>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                ref={navigationNextRef}
                className={`${styles.nextButton} ${
                  nextDisabled ? styles.disabled : ''
                }`}
                onClick={nextSlide}
              >
                <Arrow />
              </div>
            </div>
          </div>
        )}

        <div className={styles.bottomContainer}>
          {productType !== ProductTypes.EMBROIDERY && (
            <>
              <span>
                {t('totalSectors')} {info.sections}
              </span>
              <div className={styles.scrollbarRow}>
                <div ref={navigationPrevRef} onClick={prevSlide}>
                  <Arrow
                    className={`${styles.left} ${
                      prevDisabled ? styles.disabled : ''
                    }`}
                  />
                </div>
                <div className="swiper-scrollbar">
                  <div className="swiper-scrollbar-drag" />
                </div>
                <div ref={navigationNextRef} onClick={nextSlide}>
                  <Arrow
                    className={`${styles.right} ${
                      nextDisabled ? styles.disabled : ''
                    }`}
                  />
                </div>
              </div>
            </>
          )}
          <div className={styles.instructionTextRow}>
            <img
              className={popupOpen ? styles.hidden : undefined}
              src={info.image_url}
              alt="small photo"
              onClick={() => setPopupOpen(true)}
            />
            <div className={styles.instructionText}>
              <Trans i18nKey={dataByTypeMap[productType].text} />
              {productType === ProductTypes.EMBROIDERY && (
                <p>
                  <Trans i18nKey={'embroideryHighlightInfo'} />
                </p>
              )}
              <Link to={info.pdf_url} target="_blank">
                {t('openPDF')}
              </Link>
            </div>
          </div>
        </div>
        <div className={popupOpen ? styles.popup : styles.hidden}>
          <img src={info.image_url} alt="small photo" />
          <Button
            variant="tertiary"
            onClick={() => setPopupOpen(false)}
            text={t('close')}
          />
        </div>
      </div>
    </div>
  );
};

export default InteractiveInstruction;
