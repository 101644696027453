import React, { FC, useState, useEffect, useContext } from 'react';
import Button from '../Button';
import '../../App.scss';

import { ReactComponent as Wildberries } from '../../assets/wildberries.svg';
import { ReactComponent as Ozon } from '../../assets/ozon.svg';
import styles from './styles.module.scss';
import { colorMap } from '../../constants/colorMap';
import { useTranslation } from 'react-i18next';
import { API_ROUTES } from '../../constants/api_routes';
import api from '../../services/api';
import { LinkType } from '../../types/types';
import { PhotoContext } from '../../context/PhotoProvider';
import { ProductTypes } from '../../types/enums';
import { Link } from 'react-router-dom';
interface IMarketplaceBlockProps {
  type: 'wb' | 'ozon';
  size: 'medium' | 'large';
}

interface ILinks {
  code: string;
  url: string;
}

const linksMap = {
  [ProductTypes.MOSAIC]: {
    wb: 'wb-buy',
    ozon: 'ozon-buy',
  },
  [ProductTypes.PAINTING]: {
    wb: 'wb-buy-painting',
    ozon: 'ozon-buy-painting',
  },
  [ProductTypes.EMBROIDERY]: {
    wb: 'wb-buy-embroidery',
    ozon: 'ozon-buy-embroidery',
  },
  [ProductTypes.DOT_PAINTING]: {
    wb: 'wb-buy-dot-painting',
    ozon: 'ozon-buy-dot-painting',
  },
};

const MarketplaceBlock: FC<IMarketplaceBlockProps> = ({
  type = 'wb',
  size = 'medium',
}) => {
  const { t } = useTranslation();
  const [urls, setUrls] = useState({ url_wildberryes: '', url_ozon: '' });
  const { productType } = useContext(PhotoContext);
  const [links, setLinks] = useState<ILinks[]>([
    {
      code: '',
      url: '',
    },
  ]);

  const getData = async () => {
    const wb_main =
      links.find((el: LinkType) => el.code === 'wb-main')?.url || '';
    const ozon_main =
      links.find((el: LinkType) => el.code === 'ozon-main')?.url || '';
    const wb_buy =
      links.find((el: LinkType) => el.code === linksMap[productType].wb)?.url ||
      '';
    const ozon_buy =
      links.find((el: LinkType) => el.code === linksMap[productType].ozon)
        ?.url || '';

    setUrls(
      window.location.pathname === '/'
        ? { url_wildberryes: wb_main, url_ozon: ozon_main }
        : { url_wildberryes: wb_buy, url_ozon: ozon_buy },
    );
  };

  useEffect(() => {
    api({
      URL: API_ROUTES.LINKS,
      options: {
        method: 'GET',
      },
    }).then((res: ILinks[]) => setLinks(res));
  }, []);

  useEffect(() => {
    getData();
  }, [productType, links]);

  return (
    <div
      className={styles[size]}
      style={type && { background: `${colorMap[type]}1)` }}
    >
      <p className={styles[`text-${size}`]}>
        {type === 'wb' ? 'Wildberries' : 'OZON'}
      </p>
      <Link
        className={styles.link}
        to={type === 'wb' ? urls.url_wildberryes : urls.url_ozon}
        target="_blank"
      >
        <Button
          text={t('go')}
          size={size}
          variant="primary-color"
          color={type}
        />
      </Link>

      <div className={styles[type]}>
        {type === 'wb' ? (
          <Wildberries className={styles[`${type}-${size}`]} />
        ) : (
          <Ozon className={styles[`${type}-${size}`]} />
        )}
      </div>
    </div>
  );
};

export default MarketplaceBlock;
