import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Block from '../../components/Block';
import { APP_ROUTES } from '../../constants/appRoutes';
import mozaicBackground from '../../assets/mozaicBackground.svg';
import markersBackground from '../../assets/markersBackground.svg';
import paintBackground from '../../assets/paintBackground.svg';
import paintByNumbersBackground from '../../assets/paintByNumbersBackground.svg';
import paintByNumbersBackgroundMobile from '../../assets/paintByNumbersBackgroundMobile.svg';
import threadsBackground from '../../assets/threadsBackground.svg';
import prizeDrawBackground from '../../assets/prizeDrawBackground.svg';
import mozaicBgMobile from '../../assets/mozaicBgMobile.svg';
import markersBgMobile from '../../assets/markerBgMobile.svg';
import paintBgMobile from '../../assets/paintBgMobile.svg';
import threadsBgMobile from '../../assets/threadsBgMobile.svg';
import prizeDrawBgMobile from '../../assets/prizeDrawBgMobile.svg';
import styles from './styles.module.scss';
import { Button } from '../../components';
import { PhotoContext } from '../../context/PhotoProvider';
import { ProductTypes } from '../../types/enums';

const Catalogue = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setProductType } = useContext(PhotoContext);

  const navigateToDiamondPhotoMosaic = () => {
    setProductType(ProductTypes.MOSAIC);
    navigate(APP_ROUTES.DIAMOND_PHOTO_MOSAIC);
  };
  const navigateToPainting = () => {
    setProductType(ProductTypes.PAINTING);
    navigate(APP_ROUTES.PAINTING);
  };
  const navigateToDotPainting = () => {
    setProductType(ProductTypes.DOT_PAINTING);
    navigate(APP_ROUTES.DOT_PAINTING);
  };
  const navigateToEmbroidery = () => {
    setProductType(ProductTypes.EMBROIDERY);
    navigate(APP_ROUTES.EMBROIDERY);
  };
  const navigateToPrizeDraw = () => {
    navigate(APP_ROUTES.PRIZE_DRAW);
  };
  const navigateToSubscribe = (type: string) => {
    navigate(APP_ROUTES.SUBSCRIBE, { state: { type } });
  };
  const navigateBack = () => {
    navigate(APP_ROUTES.MAIN_PAGE);
  };

  const blocks = [
    {
      description: t('diamondMosaic'),
      buttonText: t('choose'),
      buttonAction: navigateToDiamondPhotoMosaic,
      color: 'purple',
      background: `url(${mozaicBackground})`,
      backgroundMobile: `url(${mozaicBgMobile})`,
    },
    {
      text: t('painting'),
      description: t('paint'),
      buttonText: t('choose'),
      color: 'pink',
      background: `url(${paintBackground})`,
      backgroundMobile: `url(${paintBgMobile})`,
      buttonAction: navigateToPainting,
    },
    {
      text: t('paintingByNumbers'),
      description: t('dots'),
      buttonText: t('choose'),
      color: 'blue',
      background: `url(${paintByNumbersBackground})`,
      backgroundMobile: `url(${paintByNumbersBackgroundMobile})`,
      buttonAction: navigateToDotPainting,
      isLongText: true,
    },
    {
      text: t('painting'),
      description: t('markers'),
      buttonText: t('choose'),
      color: 'blue',
      background: `url(${markersBackground})`,
      backgroundMobile: `url(${markersBgMobile})`,
      buttonAction: () => navigateToSubscribe('markers'),
    },
    {
      text: t('painting'),
      description: t('threads'),
      buttonText: t('choose'),
      color: 'yellow',
      background: `url(${threadsBackground})`,
      backgroundMobile: `url(${threadsBgMobile})`,
      buttonAction: () => navigateToSubscribe('threads'),
    },
    {
      text: t('embroidery'),
      buttonText: t('choose'),
      color: 'pink',
      background: `url(${threadsBackground})`,
      backgroundMobile: `url(${threadsBgMobile})`,
      buttonAction: navigateToEmbroidery,
    },
    {
      description: t('prizeDrawing'),
      buttonText: t('go'),
      color: 'orange',
      background: `url(${prizeDrawBackground})`,
      backgroundMobile: `url(${prizeDrawBgMobile})`,
      buttonAction: () => navigateToPrizeDraw(), // TODO: prize page
    },
  ];

  return (
    <div className={styles.blocks}>
      {blocks.map((block: any, key) => (
        <Block
          key={key}
          text={block.text}
          color={block.color}
          background={block.background}
          backgroundMobile={block.backgroundMobile}
          description={block.description}
          buttonText={block.buttonText}
          buttonAction={block.buttonAction}
          isLongText={block.isLongText}
        />
      ))}
      <Button variant="tertiary" onClick={navigateBack} text={t('back')} />
    </div>
  );
};

export default Catalogue;
