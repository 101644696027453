import { API_URL } from '../constants/api';

interface IApiProps {
  URL: string;
  method?: string;
  options?: { body?: any; headers?: any; method?: string };
  queryParam?: string;
}

const api = async ({ URL, options = {} }: IApiProps) => {
  if (!options.method) {
    options.method = 'GET';
  }
  const headers = new Headers();

  if (options.body instanceof FormData) {
    headers.append('Content-Type', 'multipart/form-data');
  } else {
    headers.append('Content-Type', 'application/json');
    options.body = JSON.stringify(options.body);
  }
  options.headers = headers;
  try {
    const generalFetch = await fetch(`${API_URL}${URL}`, options);
    return await generalFetch.json();
  } catch (e) {
    console.log(e);
  }
};

export default api;
