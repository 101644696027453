import React, { Dispatch, FC, useEffect, useState } from 'react';
import styles from '../style.module.scss';
import polygon from '../../../assets/icons/polygonLarge.svg';
import { PARAGRAPH } from '../../../constants/languages';
import Surface from '../../../components/Surface';

interface IComponentProps {
  id: number;
  question: string;
  answer: string;
  isExpand: boolean;
  setExpandItem: Dispatch<React.SetStateAction<number | null>>;
}

const ListItem: FC<IComponentProps> = ({
  id,
  question,
  answer,
  isExpand,
  setExpandItem,
}) => {
  const [paragraphs, setParagraphs] = useState<string[]>([]);

  useEffect(() => {
    if (answer.includes(PARAGRAPH)) {
      setParagraphs(answer.split(PARAGRAPH));
    }
  }, [answer]);

  return (
    <li
      className={`${styles.listItem} ${isExpand ? styles.listItemActive : ''}`}
    >
      <div
        className={styles.header}
        onClick={() => setExpandItem(isExpand ? null : id)}
      >
        {question}
        <img
          className={`${styles.arrow} ${isExpand ? styles.arrowActive : ''}`}
          src={polygon}
          alt=""
        />
      </div>
      <div className={`${styles.hidden} ${isExpand ? styles.visible : ''}`}>
        <Surface color="white" radius="21px">
          <div className={styles.paragraphs}>
            {paragraphs.length ? (
              <>
                {paragraphs.map((paragraph, index) => (
                  <p key={index} className={styles.paragraph}>
                    {paragraph}
                  </p>
                ))}
              </>
            ) : (
              <>{answer}</>
            )}
          </div>
        </Surface>
      </div>
    </li>
  );
};

export default ListItem;
