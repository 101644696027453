import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Mail } from '../../assets/icons/mail.svg';
import { ReactComponent as ViberLogo } from '../../assets/icons/viber.svg';
import { ReactComponent as TelegramLogo } from '../../assets/icons/telegram.svg';
import { ReactComponent as WhatsappLogo } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as InstagramLogo } from '../../assets/icons/instagram.svg';
import '../../App.scss';

import styles from './styles.module.scss';
import { LinkType } from '../../types/types';
import { API_ROUTES } from '../../constants/api_routes';
import api from '../../services/api';

const Footer = () => {
  const getCurrentYear = useCallback(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
  }, []);
  const { t } = useTranslation();
  const [urls, setUrls] = useState<LinkType[]>([]);

  const findUrl = (code: string) =>
    urls.find((el) => el.code === code)?.url ?? '';

  const getData = async () => {
    setUrls(
      await api({
        URL: API_ROUTES.LINKS,
        options: {
          method: 'GET',
        },
      }),
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.footer}>
      <div className={styles.innerContainer}>
        <p className={styles.support}>{t('support')}</p>
        <div className={styles.iconsWrapper}>
          <Link
            to={findUrl('mail')}
            target="_blank"
            className={styles.iconsWrapper__item}
          >
            <Mail />
            <p className={styles.footerText}>{t('mail')}</p>
          </Link>
          <Link
            to={findUrl('telegram')}
            target="_blank"
            className={styles.iconsWrapper__item}
          >
            <TelegramLogo />
            <p className={styles.footerText}>Telegram</p>
          </Link>
          <Link
            to={findUrl('viber')}
            target="_blank"
            className={styles.iconsWrapper__item}
          >
            <ViberLogo />
            <p className={styles.footerText}>Viber</p>
          </Link>
          <Link
            to={findUrl('whatsapp')}
            target="_blank"
            className={styles.iconsWrapper__item}
          >
            <WhatsappLogo />
            <p className={styles.footerText}>WhatsApp</p>
          </Link>
          <Link
            to={findUrl('instagram')}
            target="_blank"
            className={styles.iconsWrapper__item}
          >
            <InstagramLogo />
            <p className={styles.footerText}>Instragram</p>
          </Link>
        </div>
        <div className={styles.footerRight}>
          <p className={styles.phone}>+375 (29) 365-44-44</p>
          <p className={styles.footerText}>
            Lakarti &#169; {getCurrentYear()} {t('copyright')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
