import React, { FC } from 'react';
import Button from '../Button';
import '../../App.scss';

import styles from './styles.module.scss';
import { colorMap, Colors } from '../../constants/colorMap';

interface IButtonBlockProps {
  text: string;
  description?: string | null;
  buttonText: string;
  color?: Colors;
  buttonAction: (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => void | Promise<void> | object;
}

const ButtonBlock: FC<IButtonBlockProps> = ({
  text,
  description,
  buttonText,
  buttonAction,
  color = 'purple',
}) => {
  return (
    <div
      className={styles.block}
      style={color && { background: `${colorMap[color]}1)` }}
    >
      <div>
        <p className={styles.text}>{text}</p>
        <p className={styles.description}>{description}</p>
      </div>
      <Button
        text={buttonText}
        onClick={buttonAction}
        size="large"
        variant="primary-color"
        color={color}
      />
    </div>
  );
};

export default ButtonBlock;
