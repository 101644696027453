import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import UploadContainer from '../../components/UploadContainer';
import { APP_ROUTES } from '../../constants/appRoutes';

const UploadPhotoWithCode = () => {
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <UploadContainer
      title={t('uploadAnyPictureFromYourLib')}
      text={t('moveTheImage')}
      description={t('orCLickTheBotton')}
      buttonText={t('upload')}
      buttonAction={() => fileInputRef.current?.click()}
      fileInputRef={fileInputRef}
      withFileInp={true}
      navigateNextLink={APP_ROUTES.EDITOR}
    />
  );
};

export default UploadPhotoWithCode;
