import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import '../../App.scss';

import styles from './styles.module.scss';
import MarketplaceBlock from '../../components/MarketplaceBlock';
import { Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';
import { PhotoContext } from '../../context/PhotoProvider';
import { ProductTypes } from '../../types/enums';

const routesMap = {
  [ProductTypes.MOSAIC]: APP_ROUTES.DIAMOND_PHOTO_MOSAIC,
  [ProductTypes.PAINTING]: APP_ROUTES.PAINTING,
  [ProductTypes.EMBROIDERY]: APP_ROUTES.EMBROIDERY,
  [ProductTypes.DOT_PAINTING]: APP_ROUTES.DOT_PAINTING,
};

const Purchase = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { productType } = useContext(PhotoContext);

  const navigateBack = () => {
    navigate(routesMap[productType]);
  };

  return (
    <div className={styles.purchaseContainer}>
      <h1>{t('clickOnTheMarketplaceIconToBuy')}</h1>
      <div className={styles.marketplacesBlocks}>
        <MarketplaceBlock type="wb" size="large" />
        <MarketplaceBlock type="ozon" size="large" />
      </div>
      <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
    </div>
  );
};

export default Purchase;
