import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { APP_ROUTES } from '../../constants/appRoutes';

import ok from '../../assets/icons/ok.svg';
import notOk from '../../assets/icons/notOk.svg';
import girl from '../../assets/girl.png';
import girlPortrait from '../../assets/girlPortrait.png';
import girlBlack from '../../assets/girlBlack.png';
import girlPortraitBlack from '../../assets/girlPortraitBlack.png';
import girlEmbroidery from '../../assets/girlEmbroidery.png';
import girlDotPainting from '../../assets/girlDotPainting.png';
import girlPortraitDotPainting from '../../assets/girlPortraitDotPainting.png';
import girlPortraitEmbroidery from '../../assets/girlPortraitEmbroidery.png';
import styles from './styles.module.scss';
import Surface from '../../components/Surface';
import Button from '../../components/Button';
import { ProductTypes } from '../../types/enums';
import { PhotoContext } from '../../context/PhotoProvider';

const routesMap = {
  [ProductTypes.EMBROIDERY]: {
    next: (isNavigateToEmail: boolean) =>
      isNavigateToEmail
        ? APP_ROUTES.UPLOAD_EMBROIDERY_PHOTO_WITH_CODE
        : APP_ROUTES.UPLOAD_EMBROIDERY_PHOTO,
    back: (isNavigateToEmail: boolean) =>
      isNavigateToEmail
        ? APP_ROUTES.EMBROIDERY_INSTRUCTION
        : APP_ROUTES.CHOOSE_SIZE_EMBROIDERY,
    tittle: 'howToChoosePhotoForTheEmbroidery',
    girlImg: girlEmbroidery,
    girlPortraitImg: girlPortraitEmbroidery,
  },
  [ProductTypes.MOSAIC]: {
    next: (isNavigateToEmail: boolean) =>
      isNavigateToEmail
        ? APP_ROUTES.UPLOAD_PHOTO_WITH_CODE
        : APP_ROUTES.UPLOAD_PHOTO,
    back: (isNavigateToEmail: boolean) =>
      isNavigateToEmail
        ? // APP_ROUTES.INSTRUCTION
          APP_ROUTES.CHOOSE_STYLE
        : APP_ROUTES.CHOOSE_SIZE_DIAMOND_PHOTO_MOSAIC,
    tittle: 'howToChoosePhotoForTheMosaic',
    girlImg: girlBlack,
    girlPortraitImg: girlPortraitBlack,
  },
  [ProductTypes.PAINTING]: {
    next: () => APP_ROUTES.CATALOGUE,
    back: () => APP_ROUTES.CATALOGUE,
    tittle: 'howToChoosePhotoForThePainting',
    girlImg: girlBlack,
    girlPortraitImg: girlPortraitBlack,
  },
  [ProductTypes.DOT_PAINTING]: {
    next: (isNavigateToEmail: boolean) =>
      isNavigateToEmail
        ? APP_ROUTES.UPLOAD_DOT_PAINTING_PHOTO_WITH_CODE
        : APP_ROUTES.UPLOAD_DOT_PAINTING_PHOTO,
    back: (isNavigateToEmail: boolean) =>
      isNavigateToEmail
        ? APP_ROUTES.DOT_PAINTING_INSTRUCTION
        : APP_ROUTES.CHOOSE_SIZE_DOT_PAINTING,
    tittle: 'howToChoosePhotoForThePainting',
    girlImg: girlDotPainting,
    girlPortraitImg: girlPortraitDotPainting,
  },
};

const HowToChoosePhoto = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const props = location.state;
  const { productType } = useContext(PhotoContext);

  const navigateToUpload = () => {
    navigate(routesMap[productType].next(!!props?.navigateToEmail));
  };
  const navigateBack = () => {
    navigate(routesMap[productType].back(!!props?.navigateToEmail));
  };

  return (
    <div>
      <h1 className={styles.title}>{t(routesMap[productType].tittle)}</h1>
      <Surface radius="26px">
        <p className={styles.description}>
          {t('zoomLargeObjectAsCloseAsPossible')}
        </p>
      </Surface>
      <div className={styles.wrapper}>
        <div className={styles.photos}>
          <img
            className={styles.photo_element}
            src={girlPortrait}
            alt="girl portrait"
          />
          <img
            className={styles.photo_element}
            src={routesMap[productType].girlPortraitImg}
            alt="girl portrait black"
          />
          <img className={styles.photo_element} src={girl} alt="girl" />
          <img
            className={styles.photo_element}
            src={routesMap[productType].girlImg}
            alt="girl black"
          />
          <div className={styles.icons}>
            <img src={ok} className={styles.icon_element} alt="ok" />
            <img src={notOk} className={styles.icon_element} alt="not ok" />
          </div>
        </div>
        <div className={styles.buttons}>
          <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
          <Button
            variant="secondary"
            text={t('next')}
            onClick={navigateToUpload}
          />
        </div>
      </div>
    </div>
  );
};

export default HowToChoosePhoto;
