import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import Surface from '../../components/Surface';
import { Button } from '../../components';
import { useNavigate } from 'react-router-dom';

const Policy = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const policySections = [
    {
      title: <Trans i18nKey="policyTitle1" />,
      text: <Trans i18nKey="policyText1" />,
    },
    {
      title: <Trans i18nKey="policyTitle2" />,
      text: <Trans i18nKey="policyText2" />,
    },
    {
      title: <Trans i18nKey="policyTitle3" />,
      text: <Trans i18nKey="policyText3" />,
    },
    {
      title: <Trans i18nKey="policyTitle4" />,
      text: <Trans i18nKey="policyText4" />,
    },
    {
      title: <Trans i18nKey="policyTitle5" />,
      text: <Trans i18nKey="policyText5" />,
    },
    {
      title: <Trans i18nKey="policyTitle6" />,
      text: <Trans i18nKey="policyText6" />,
    },
    {
      title: <Trans i18nKey="policyTitle7" />,
      text: <Trans i18nKey="policyText7" />,
    },
    {
      title: <Trans i18nKey="policyTitle8" />,
      text: <Trans i18nKey="policyText8" />,
    },
    {
      title: <Trans i18nKey="policyTitle9" />,
      text: <Trans i18nKey="policyText9" />,
    },
    {
      title: <Trans i18nKey="policyTitle10" />,
      text: <Trans i18nKey="policyText10" />,
    },
  ];
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.wrapper}>
      <Surface radius="21px">
        <div className={styles.content}>
          <h1 className="textCenter">{t('policyTitle')}</h1>
          {policySections.map((section, index) => (
            <div key={index}>
              <h2>{section.title}</h2>
              <Surface radius="21px" color="white">
                <div className={styles.paragraph}>{section.text}</div>
              </Surface>
            </div>
          ))}
        </div>
      </Surface>
      <Button variant="tertiary" text={t('back')} onClick={navigateBack} />
    </div>
  );
};

export default Policy;
