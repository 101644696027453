import { APP_ROUTES } from '../constants/appRoutes';
import MainPage from '../containers/MainPage';
import Catalogue from '../containers/Catalogue';
import EditorPage from '../containers/EditorPage';
import DiamondPhotoMosaic from '../containers/DiamondPhotoMosaic';
import ChooseSizeDiamondPhotoMosaic from '../containers/ChooseSizeDiamondPhotoMosaic';
import HowToChoosePhoto from '../containers/HowToChoosePhoto';
import FAQ from '../containers/FAQ';
import UploadPhoto from '../containers/UploadPhoto';
import PhotoStyles from '../containers/PhotoStyle';
import Instruction from '../containers/Instruction';
import InteractiveInstruction from '../containers/InteractiveInstruction';
import HowToUseInstruction from '../containers/HowToUseInstruction';
import EnterEmail from '../containers/EnterEmail';
import PhotoStylesInstruction from '../containers/PhotoStylesInstruction';
import InstructionLink from '../containers/InstructionLink';
import Purchase from '../containers/Purchase';
import NotFoundPage from '../containers/404page';
import SubscribePage from '../containers/SubscribePage';
import SubscribeSuccess from '../containers/SubscribeSuccess';
import Policy from '../containers/Policy';
import PrizeDraw from '../containers/PrizeDraw';
import UploadPhotoWithCode from '../containers/UploadPhotoWithCode';
import VideoTutorial from '../containers/VideoTutorial';
import Painting from '../containers/Painting';
import Embroidery from '../containers/Embroidery';
import PaintingInstruction from '../containers/PaintingInstruction';
import { JSX } from 'react';
import DotPainting from '../containers/DotPainting';
import ChooseStyle from '../containers/ChooseStyle';

type routeDataType = {
  path: string;
  element: () => JSX.Element;
};

const commonRoutes: routeDataType[] = [
  {
    path: APP_ROUTES.MAIN_PAGE,
    element: MainPage,
  },
  {
    path: APP_ROUTES.CATALOGUE,
    element: Catalogue,
  },
  {
    path: APP_ROUTES.EDITOR,
    element: EditorPage,
  },
  {
    path: APP_ROUTES.HOW_TO_CHOOSE_PHOTO,
    element: HowToChoosePhoto,
  },
  {
    path: APP_ROUTES.FAQ,
    element: FAQ,
  },
  {
    path: APP_ROUTES.ENTER_EMAIL,
    element: EnterEmail,
  },
  {
    path: APP_ROUTES.ENTER_EMAIL,
    element: EnterEmail,
  },
  {
    path: APP_ROUTES.PHOTO_STYLES_INSTRUCTION,
    element: PhotoStylesInstruction,
  },
  {
    path: APP_ROUTES.INSTRUCTION_LINK,
    element: InstructionLink,
  },
  {
    path: APP_ROUTES.NOT_FOUND_PAGE,
    element: NotFoundPage,
  },
  {
    path: APP_ROUTES.SUBSCRIBE,
    element: SubscribePage,
  },
  {
    path: APP_ROUTES.SUBSCRIBE_SUCCESS,
    element: SubscribeSuccess,
  },
  {
    path: APP_ROUTES.POLICY,
    element: Policy,
  },
  {
    path: APP_ROUTES.PRIZE_DRAW,
    element: PrizeDraw,
  },
];

const diamondRoutes: routeDataType[] = [
  {
    path: APP_ROUTES.DIAMOND_PHOTO_MOSAIC,
    element: DiamondPhotoMosaic,
  },
  {
    path: APP_ROUTES.CHOOSE_SIZE_DIAMOND_PHOTO_MOSAIC,
    element: ChooseSizeDiamondPhotoMosaic,
  },
  {
    path: APP_ROUTES.UPLOAD_PHOTO,
    element: UploadPhoto,
  },
  {
    path: APP_ROUTES.PHOTO_STYLE,
    element: PhotoStyles,
  },
  {
    path: APP_ROUTES.INSTRUCTION,
    element: Instruction,
  },
  {
    path: APP_ROUTES.PURCHASE,
    element: Purchase,
  },
  {
    path: APP_ROUTES.UPLOAD_PHOTO_WITH_CODE,
    element: UploadPhotoWithCode,
  },
  {
    path: APP_ROUTES.VIDEO_TUTORIAL,
    element: VideoTutorial,
  },
  {
    path: APP_ROUTES.INTERACTIVE_INSTRUCTION,
    element: InteractiveInstruction,
  },
  {
    path: APP_ROUTES.HOW_TO_USE_INSTRUCTION,
    element: HowToUseInstruction,
  },
  {
    path: APP_ROUTES.CHOOSE_STYLE,
    element: ChooseStyle,
  },
];

const paintingRoutes: routeDataType[] = [
  {
    path: APP_ROUTES.CHOOSE_SIZE_PAINTING,
    element: ChooseSizeDiamondPhotoMosaic,
  },
  {
    path: APP_ROUTES.UPLOAD_PAINTING_PHOTO,
    element: UploadPhoto,
  },
  {
    path: APP_ROUTES.PAINTING_PHOTO_STYLE,
    element: PhotoStyles,
  },
  {
    path: APP_ROUTES.PAINTING_INSTRUCTION,
    element: Instruction,
  },
  {
    path: APP_ROUTES.PURCHASE_PAINTING,
    element: Purchase,
  },
  {
    path: APP_ROUTES.UPLOAD_PAINTING_PHOTO_WITH_CODE,
    element: UploadPhotoWithCode,
  },
  {
    path: APP_ROUTES.VIDEO_TUTORIAL_PAINTING,
    element: VideoTutorial,
  },
  {
    path: APP_ROUTES.PAINTING,
    element: Painting,
  },
  {
    path: APP_ROUTES.PAINTING_TUTORIAL,
    element: PaintingInstruction,
  },
];

const embroideryRoutes: routeDataType[] = [
  {
    path: APP_ROUTES.CHOOSE_SIZE_EMBROIDERY,
    element: ChooseSizeDiamondPhotoMosaic,
  },
  {
    path: APP_ROUTES.HOW_TO_CHOOSE_EMBROIDERY_PHOTO,
    element: HowToChoosePhoto,
  },
  {
    path: APP_ROUTES.UPLOAD_EMBROIDERY_PHOTO,
    element: UploadPhoto,
  },
  {
    path: APP_ROUTES.EMBROIDERY_PHOTO_STYLE,
    element: PhotoStyles,
  },
  {
    path: APP_ROUTES.EMBROIDERY_INSTRUCTION,
    element: Instruction,
  },
  {
    path: APP_ROUTES.PURCHASE_EMBROIDERY,
    element: Purchase,
  },
  {
    path: APP_ROUTES.UPLOAD_EMBROIDERY_PHOTO_WITH_CODE,
    element: UploadPhotoWithCode,
  },
  {
    path: APP_ROUTES.VIDEO_TUTORIAL_EMBROIDERY,
    element: VideoTutorial,
  },
  {
    path: APP_ROUTES.EMBROIDERY,
    element: Embroidery,
  },
  {
    path: APP_ROUTES.EMBROIDERY_INTERACTIVE_INSTRUCTION,
    element: InteractiveInstruction,
  },
  {
    path: APP_ROUTES.HOW_TO_USE_EMBROIDERY_INSTRUCTION,
    element: HowToUseInstruction,
  },
];

const dotPaintingRoutes: routeDataType[] = [
  {
    path: APP_ROUTES.CHOOSE_SIZE_DOT_PAINTING,
    element: ChooseSizeDiamondPhotoMosaic,
  },
  {
    path: APP_ROUTES.HOW_TO_CHOOSE_DOT_PAINTING_PHOTO,
    element: HowToChoosePhoto,
  },
  {
    path: APP_ROUTES.UPLOAD_DOT_PAINTING_PHOTO,
    element: UploadPhoto,
  },
  {
    path: APP_ROUTES.DOT_PAINTING_PHOTO_STYLE,
    element: PhotoStyles,
  },
  {
    path: APP_ROUTES.DOT_PAINTING_INSTRUCTION,
    element: Instruction,
  },
  {
    path: APP_ROUTES.PURCHASE_DOT_PAINTING,
    element: Purchase,
  },
  {
    path: APP_ROUTES.UPLOAD_DOT_PAINTING_PHOTO_WITH_CODE,
    element: UploadPhotoWithCode,
  },
  {
    path: APP_ROUTES.VIDEO_TUTORIAL_DOT_PAINTING,
    element: VideoTutorial,
  },
  {
    path: APP_ROUTES.DOT_PAINTING,
    element: DotPainting,
  },
  {
    path: APP_ROUTES.DOT_PAINTING_INTERACTIVE_INSTRUCTION,
    element: InteractiveInstruction,
  },
  {
    path: APP_ROUTES.HOW_TO_USE_DOT_PAINTING_INSTRUCTION,
    element: HowToUseInstruction,
  },
];

export default [
  ...commonRoutes,
  ...diamondRoutes,
  ...paintingRoutes,
  ...embroideryRoutes,
  ...dotPaintingRoutes,
];
