export const PHOTO_KIT = [
  {
    id: 1,
    art_type: 'ORIGINAL',
    name: 'Original',
    color: 'purple',
  },
  {
    id: 2,
    art_type: 'VINTAGE',
    name: 'Sepia',
    color: 'pink',
  },
  {
    id: 3,
    art_type: 'POP_ART',
    name: 'Pop Art',
    color: 'blue',
  },
];
