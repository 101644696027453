import React, { useState, useEffect } from 'react';
import Surface from '../Surface';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
interface ILoaderProps {
  delay: number;
  text?: boolean;
}

const Loader = ({ delay, text }: ILoaderProps) => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setPercentage((prev) => (prev >= 99 ? 99 : prev + 1));
    }, delay);
    return () => {
      clearInterval(timer);
    };
  }, [percentage]);

  return (
    <Surface>
      <div className={styles.wrapper}>
        <div className={styles.loading}>
          <div className={styles.spinner}>
            {[...Array(8)].map((el, i) => (
              <div key={i} className={styles[`bar${i + 1}`]} />
            ))}
          </div>
          <div className={styles.percent}>{percentage} %</div>
        </div>
        {text && <h2 className={styles.header}>{t('smile')}</h2>}
      </div>
    </Surface>
  );
};

export default Loader;
