import React, { memo, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { CompletionType } from '../../../types/enums';

type InteractiveSchemaProps = {
  info: {
    image_url: string;
    sections: number;
  };
  sectorsInfo: { [key: number]: CompletionType };
  onEnterSector: (value: number | '') => void;
  sector: number;
};

// sizes info
// 4 - '21 x 30' - 187 sectors, 11 x 17, cells 110 x 165
// 1 - '30 x 40' - 374 sectors, 17 x 22, cells 165 x 220
// 3 - '40 x 50' - 616 sectors, 22 x 28, cells 220 x 275

const sizesMap = {
  187: {
    width: 110,
    height: 165,
    cellCount: 11,
    fontSize: window.innerWidth > 780 ? 16 : 8,
    lineWidth: window.innerWidth > 780 ? 4 : 1,
    bigCellLineWidth: window.innerWidth > 780 ? 2 : 1,
    shiftY: window.innerWidth > 780 ? 20 : 8,
    shiftX: window.innerWidth > 780 ? 10 : 2,
  },
  374: {
    width: 165,
    height: 220,
    cellCount: 17,
    fontSize: window.innerWidth > 780 ? 10 : 4,
    shiftY: window.innerWidth > 780 ? 15 : 5,
    shiftX: window.innerWidth > 780 ? 3 : 1,
    bigCellLineWidth: window.innerWidth > 780 ? 2 : 1,
    lineWidth: window.innerWidth > 780 ? 2 : 1,
  },
  616: {
    width: 220,
    height: 275,
    cellCount: 22,
    fontSize: window.innerWidth > 780 ? 10 : 4,
    shiftY: window.innerWidth > 780 ? 12 : 5,
    shiftX: window.innerWidth > 780 ? 4 : 1,
    bigCellLineWidth: window.innerWidth > 780 ? 2 : 1,
    lineWidth: window.innerWidth > 780 ? 2 : 1,
  },
};

type SizeType = 187 | 374 | 616;

const cellStateColors = {
  [CompletionType.PARTIAL]: 'rgba(251,60,242,0.8)',
  [CompletionType.FULL]: 'rgba(140,251,60,0.8)',
};

const width = window.innerWidth > 780 ? 740 : 290;
const height = window.innerWidth > 780 ? 960 : 370;

const InteractiveSchema = memo(function InteractiveSchema({
  info,
  sectorsInfo,
  onEnterSector,
  sector,
}: InteractiveSchemaProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasWrapperRef = useRef<HTMLDivElement>(null);
  const cellWidth = width / sizesMap[info.sections as SizeType]?.width;
  const cellHeight = height / sizesMap[info.sections as SizeType]?.height;
  const [scale, setScale] = useState(1);
  const [prevScale, setPrevScale] = useState(1);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
  const [img, setImg] = useState<HTMLImageElement | null>(null);
  const [shift, setShift] = useState({
    x: 0,
    y: 0,
  });
  const { fontSize, shiftX, shiftY, lineWidth, bigCellLineWidth, cellCount } =
    sizesMap[(info.sections || 187) as SizeType];

  useEffect(() => {
    const img = new Image();
    img.src = info.image_url;
    img.onload = () => {
      setImg(img);
    };
  }, [info]);

  useEffect(() => {
    if (canvasRef?.current) setCtx(canvasRef.current.getContext('2d'));
  }, [canvasRef]);

  useEffect(() => {
    if (ctx && img) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ctx.reset();
      ctx.setTransform(scale, 0, 0, scale, shift.x, shift.y);
      ctx.drawImage(img, 0, 0, width, height);
      // draw cell state color
      for (
        let y = 0, row = 0;
        y <= height - 0.1;
        y += cellHeight * 10, row += 1
      ) {
        for (
          let x = 0, cell = 0;
          x <= width - 0.1;
          x += cellWidth * 10, cell += 1
        ) {
          const state = sectorsInfo[cell + row * cellCount];
          if (state !== CompletionType.EMPTY) {
            ctx.fillStyle = cellStateColors[state];
            ctx.fillRect(x, y, cellWidth * 10, cellHeight * 10);
          }
        }
      }
      // draw small cells
      ctx.beginPath();

      for (let x = 0; x <= width; x += cellWidth) {
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
      }

      for (let y = 0; y <= height; y += cellHeight) {
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
      }
      ctx.lineWidth = 0.5;
      ctx.strokeStyle = '#4a4a4a';
      ctx.stroke();
      // draw big cells
      ctx.beginPath();
      for (let x = 0; x <= width; x += cellWidth * 10) {
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
      }

      for (let y = 0; y <= height; y += cellHeight * 10) {
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
      }
      ctx.lineWidth = bigCellLineWidth;
      ctx.strokeStyle = '#393939';
      ctx.stroke();
      // draw cell numbers
      ctx.strokeStyle = 'black';
      ctx.fillStyle = '#ffffff';
      ctx.font = `bold ${fontSize}px Montserrat, sans-serif`;
      ctx.textAlign = 'left';
      ctx.lineWidth = lineWidth;
      let counter = 1;
      for (let y = shiftY; y <= height; y += cellHeight * 10) {
        for (let x = shiftX; x <= width; x += cellWidth * 10) {
          ctx.strokeText(`${counter}`, x, y);
          ctx.fillText(`${counter}`, x, y);
          counter++;
        }
      }
      //draw center circle
      ctx.strokeStyle = '#ffffff';
      ctx.beginPath();
      ctx.arc(width / 2, height / 2, 5, 0, (Math.PI / 180) * 360);
      ctx.stroke();
      ctx.strokeStyle = 'black';
      ctx.beginPath();
      ctx.arc(width / 2, height / 2, 2, 0, (Math.PI / 180) * 360);
      ctx.stroke();
      //
      for (
        let y = 0, row = 0;
        y <= height - 0.1;
        y += cellHeight * 10, row += 1
      ) {
        for (
          let x = 0, cell = 0;
          x <= width - 0.1;
          x += cellWidth * 10, cell += 1
        ) {
          if (cell + row * cellCount === sector) {
            ctx.lineWidth = bigCellLineWidth;
            ctx.strokeStyle = '#99ff5e';
            ctx.rect(x, y, cellWidth * 10, cellHeight * 10);
            ctx.stroke();
          }
        }
      }
    }
  }, [info, img, scale, shift, sectorsInfo, sector]);

  const scaleHandle = (type: '+' | '-') => {
    if ((scale === 1 && type === '-') || (type === '+' && scale > 6)) {
      return;
    }
    setScale((prevState) => {
      let newScale: number;
      if (type === '+') {
        newScale = prevState + 1;
      } else {
        newScale = prevState - 1;
      }
      setPrevScale(prevState);
      return newScale;
    });
  };

  useEffect(() => {
    if (scale > 1 || (scale === 1 && prevScale > 1)) {
      const maxX = (scale - 1) * width;
      const maxY = (scale - 1) * height;
      setShift((prev) => {
        const newShiftX = (prev.x / (prevScale - 1 || 1)) * (scale - 1);
        const newShiftY = (prev.y / (prevScale - 1 || 1)) * (scale - 1);
        return {
          x: newShiftX > 0 ? 0 : Math.max(newShiftX, -maxX),
          y: newShiftY > 0 ? 0 : Math.max(newShiftY, -maxY),
        };
      });
    }
  }, [scale, prevScale]);

  const mouseUpHandle = () => {
    setIsMouseDown(false);
  };

  const mouseLeaveHandle = () => {
    setIsMouseDown(false);
  };

  const mouseDownHandle = () => {
    setIsMouseDown(true);
  };

  const mouseMoveHandle = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isMouseDown && scale > 1) {
      setShift((prev) => {
        const newShiftX = event.movementX * 2 + prev.x;
        const newShiftY = event.movementY * 2 + prev.y;
        const maxX = (scale - 1) * width;
        const maxY = (scale - 1) * height;
        return {
          x: newShiftX > 0 ? 0 : Math.max(newShiftX, -maxX),
          y: newShiftY > 0 ? 0 : Math.max(newShiftY, -maxY),
        };
      });
    }
  };

  const [previousTouch, setPrevTouch] = useState({
    x: 0,
    y: 0,
  });

  const touchMoveHandle = (event: React.TouchEvent<HTMLDivElement>) => {
    const touch = event.targetTouches[0];
    if (Math.abs(previousTouch.x - touch.pageX) > 4) {
      if (isMouseDown && scale > 1) {
        const touch = event.targetTouches[0];
        if (previousTouch.x === 0 && previousTouch.y === 0) {
          previousTouch.x = touch.pageX;
          previousTouch.y = touch.pageY;
        }
        setShift((prev) => {
          const { x, y } = previousTouch;
          const newShiftX = touch.pageX - x + prev.x;
          const newShiftY = touch.pageY - y + prev.y;
          const maxX = (scale - 1) * width;
          const maxY = (scale - 1) * height;
          setPrevTouch({
            x: touch.pageX,
            y: touch.pageY,
          });
          return {
            x: newShiftX > 0 ? 0 : Math.max(newShiftX, -maxX),
            y: newShiftY > 0 ? 0 : Math.max(newShiftY, -maxY),
          };
        });
      }
    }
  };

  const clickHandle = (event: React.MouseEvent<HTMLElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rect = event.target.getBoundingClientRect();
    const cell = Math.floor(
      (event.clientX - rect.left + Math.abs(shift.x)) /
        (cellWidth * scale * 10),
    );
    const row = Math.floor(
      (event.clientY - rect.top + Math.abs(shift.y)) /
        (cellHeight * scale * 10),
    );
    onEnterSector(row * cellCount + cell + 1);
  };

  const [lastTap, setLastTap] = useState(0);
  const touchEndHandle = (event: React.TouchEvent<HTMLDivElement>) => {
    setLastTap(event.timeStamp);
    setPrevTouch({
      x: 0,
      y: 0,
    });
    mouseUpHandle();
    if (event.timeStamp - lastTap < 400) {
      const touch = event.changedTouches[0];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const rect = event.target.getBoundingClientRect();
      const cell = Math.floor(
        (touch.clientX - rect.left + Math.abs(shift.x)) /
          (cellWidth * scale * 10),
      );
      const row = Math.floor(
        (touch.clientY - rect.top + Math.abs(shift.y)) /
          (cellHeight * scale * 10),
      );
      onEnterSector(row * cellCount + cell + 1);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.canvasWrapper}
        ref={canvasWrapperRef}
        onMouseMove={mouseMoveHandle}
        onMouseDown={mouseDownHandle}
        onMouseUp={mouseUpHandle}
        onMouseLeave={mouseLeaveHandle}
        onDoubleClick={clickHandle}
      >
        <div
          className={styles.eventWrapper}
          onTouchStart={mouseDownHandle}
          onTouchEnd={touchEndHandle}
          onTouchMove={touchMoveHandle}
        />
        <canvas ref={canvasRef} width={width} height={height} />
      </div>

      <div className={styles.control}>
        <span
          className={styles.button}
          onClick={() => {
            scaleHandle('-');
          }}
        >
          {'–'}
        </span>
        <div
          className={styles.button}
          onClick={() => {
            scaleHandle('+');
          }}
        >
          {'+'}
        </div>
      </div>
    </div>
  );
});

export default InteractiveSchema;
