import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/appRoutes';
import { Button } from '../../components';
import '../../App.scss';
import styles from './styles.module.scss';
import Surface from '../../components/Surface';

const SubscribeSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(APP_ROUTES.MAIN_PAGE);
  };

  return (
    <div className={styles.enterEmailContainer}>
      <Surface>
        <div className={styles.text}>
          <h1 className={styles.headline}>{t('subscribeSuccess')}</h1>
          <p className={styles.description}>
            {t('subscribeSuccessDescription')}
          </p>
        </div>
      </Surface>
      <Button variant="tertiary" text={t('home')} onClick={navigateBack} />
    </div>
  );
};

export default SubscribeSuccess;
