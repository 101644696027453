export const DEFAULT_COLOR_INFO = {
  o: {
    rgb: [207, 207, 207],
    hex: 'cfcfcf',
  },
  l: {
    rgb: [231, 208, 92],
    hex: 'e7d05c',
  },
  m: {
    rgb: [230, 170, 48],
    hex: 'e6aa30',
  },
  t: {
    rgb: [33, 164, 151],
    hex: '21a497',
  },
  a: {
    rgb: [90, 77, 146],
    hex: '5a4d92',
  },
  x: {
    rgb: [53, 53, 53],
    hex: '353535',
  },
  e: {
    rgb: [216, 59, 136],
    hex: 'd83b88',
  },
  b: {
    rgb: [144, 146, 147],
    hex: '909293',
  },
  c: {
    rgb: [107, 109, 109],
    hex: '6b6d6d',
  },
  d: {
    rgb: [82, 84, 88],
    hex: '525458',
  },
  p: {
    rgb: [245, 218, 172],
    hex: 'f5daac',
  },
  k: {
    rgb: [223, 167, 116],
    hex: 'dfa774',
  },
  w: {
    rgb: [175, 119, 78],
    hex: 'af774e',
  },
  f: {
    rgb: [162, 104, 65],
    hex: 'a26841',
  },
  s: {
    rgb: [88, 58, 58],
    hex: '583a3a',
  },
};
