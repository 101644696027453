import React from 'react';
import upload from '../../../assets/icons/upload.svg';
import minimize from '../../../assets/icons/minimize.svg';
import maximize from '../../../assets/icons/maximize.svg';
import left from '../../../assets/icons/left.svg';
import right from '../../../assets/icons/turnRight.svg';

import styles from './styles.module.scss';

interface IEditorControlsContainerProps {
  fileInputRef: React.RefObject<HTMLInputElement>;
  rotation: number;
  setRotation: React.Dispatch<React.SetStateAction<number>>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
}

const EditorControlsContainer = ({
  fileInputRef,
  rotation,
  setRotation,
  setZoom,
}: IEditorControlsContainerProps) => {
  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleRotateLeft = () => {
    setRotation(rotation - 90);
  };

  const handleRotateRight = () => {
    setRotation(rotation + 90);
  };

  const handleZoomIn = () => {
    setZoom((prevZoom: number) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom: number) => Math.max(0.1, prevZoom - 0.1));
  };
  return (
    <div className={styles.buttonContainer}>
      <img
        className={styles.buttonContainer__item}
        src={upload}
        alt="upload photo"
        onClick={handleUploadClick}
      />
      <img
        className={styles.buttonContainer__item}
        src={minimize}
        alt="minimize photo"
        onClick={handleZoomOut}
      />
      <img
        className={styles.buttonContainer__item}
        src={maximize}
        alt="maximize photo"
        onClick={handleZoomIn}
      />
      <img
        className={styles.buttonContainer__item}
        src={left}
        alt="turn left"
        onClick={handleRotateLeft}
      />
      <img
        className={styles.buttonContainer__item}
        src={right}
        alt="turn right"
        onClick={handleRotateRight}
      />
    </div>
  );
};

export default EditorControlsContainer;
