import React, { FC, PropsWithChildren } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import styles from './styles.module.scss';

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.main}>
      <Header />
      <div className={styles.container}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
