import { ILanguage } from '../types/interfaces';

export const languagesList: ILanguage[] = [
  { id: 0, name: 'Русский', langName: 'ru' },
  { id: 1, name: 'English', langName: 'en' },
  { id: 2, name: 'Deutsche', langName: 'de' },
  { id: 7, name: 'Беларуская', langName: 'by' },
  { id: 9, name: 'Қазақ', langName: 'kz' },
  { id: 18, name: 'Українська', langName: 'ua' },
];

export const PARAGRAPH = '{#}';
