import React, { FC } from 'react';
import '../../App.scss';
import { ReactComponent as Arrow } from '../../assets/icons/right.svg';
import { ReactComponent as ArrowSecondary } from '../../assets/icons/rightBlack.svg';
import styles from './styles.module.scss';
import { colorMap, Colors } from '../../constants/colorMap';
import { useTranslation } from 'react-i18next';

interface IButtonProps {
  text: string;
  variant?: 'primary' | 'primary-color' | 'download' | 'secondary' | 'tertiary';
  size?: 'medium' | 'large';
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => void | Promise<void> | object;
  disabled?: boolean;
  color?: Colors;
  borderColor?: Colors;
  prize?: boolean;
  className?: string;
}

const Button: FC<IButtonProps> = ({
  text,
  onClick,
  variant = 'primary',
  size = 'medium',
  disabled,
  color,
  borderColor,
  prize,
  className,
}) => {
  const { t } = useTranslation();
  const handleClick = (e: any) => {
    if (onClick && !disabled) {
      onClick(e);
    } else {
      console.log('disabled');
      return;
    }
  };
  return (
    <div className={styles[`buttonWrapper_${size}`]} onClick={handleClick}>
      <button
        className={`${styles[variant]} ${styles[size]} ${
          styles[color ? color : '']
        } ${styles[className ? className : '']} ${
          styles[borderColor ? borderColor : '']
        }`}
        disabled={disabled}
        style={
          borderColor && variant === 'tertiary'
            ? {
                borderColor: `${colorMap[borderColor]}1)`,
              }
            : borderColor && variant === 'secondary'
            ? {
                background: `${colorMap[borderColor]}1)`,
                borderColor: `${colorMap[borderColor]}1)`,
              }
            : {}
        }
      >
        {variant === 'tertiary' ? (
          text
        ) : (
          <>
            <div
              className={styles.inner}
              style={
                color && {
                  background: `${colorMap[color]}0.47)`,
                }
              }
            >
              {prize ? (
                <p className={styles.prizeDraw}>{t('prizeButton')}</p>
              ) : (
                ''
              )}

              {text}
            </div>
            {variant === 'primary' ? (
              <Arrow className={styles.arrow} />
            ) : (
              <ArrowSecondary className={styles.arrow} />
            )}
          </>
        )}
      </button>
    </div>
  );
};

export default Button;
